import { Injectable } from "@angular/core";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { DatePipe } from "@angular/common";
import {
  CHANNEL_EXCEL_HEADER,
  CITY_EXCEL_HEADER,
  CITY_UPLOAD_HEADER,
  GEOGRAPHY_EXCEL_HEADER,
  LOYALTY_PRODUCT_HEADER,
  POINT_STRUCTURE_EXCEL,
  REGION_EXCEL_HEADER,
  REGION_UPLOAD_HEADER,
  SALES_EXCEL,
  SALES_PRODUCT_EXCEL,
  STATE_EXCEL_HEADER,
  STATE_UPLOAD_HEADER,
  TargetAchievementModel,
  TARGET_EXCEL,
  USER_EXCEL,
  WHITELISTING_EXCEL,
  USER_PONITS_HEADER,
  USER_ASSIGNMENT_HEADER,
  USER_PHONE_HEADER,
  Account_Login_Report,
  CSM_Mobile_Number_Change_Log_Report,
  Enrollement_Detailed_Report,
  REDEMPTION_Report,
  REDEMPTION_MASTER,
  TARGET_AND_ACHIVEMENTS,
  LIVEPOINT,
  FIRE_TIME_SPENT,
  FIRE_MAU_TIME_SPENT,
  FIRE_ENAGEMENT_TIME_SPENT,
  FIRE_MODULE_TIME_SPENT,
  FIRE_SCHEME_TIME_SPENT,
  POINT_TRANSACTION_REPORT,
  GAP_EXCEL_TEMPLATE,
  GAP_EXCEL,
  MYSTERY_USER_EXCEL,
  MYSTERY_QUESTION_BANK,
  MYSTERY_USER_ASSIGNMENT_HEADER,
  ACHIVEMENTS,
  WHITELISTING_REPORT_EXCEL,
  USER_PONITS_HEADER_KINGSERVICE_CATEGORY,
  USER_PONITS_HEADER_KINGSERVICE_SKU,
  PENDING_ORDER_DETAIL_HEADER
} from "src/app/hubadmin/shared/models/mock-data";
import {
  BrandCategoryModel,
  BrandModel,
  ProductModel,
} from "src/app/hubadmin/shared/models/brands";
import { GeographyModel } from "src/app/hubadmin/shared/models/geography";
import { ChannelModel } from "src/app/hubadmin/shared/models/channel";
import { RegionModel } from "src/app/hubadmin/shared/models/region";
import { UserRoleModel } from "src/app/hubadmin/shared/models/user-roles";
import { LoyaltyTargetModel } from "src/app/hubadmin/shared/models/target-achievement";
import { StateModel } from "src/app/hubadmin/shared/models/state";
import { ColumnSetting } from "../model/table.model";

@Injectable({
  providedIn: "root",
})
export class ExportExcelService {
  // Redmeption
  TargetHeaders = TARGET_EXCEL;
  salesHeaders = SALES_EXCEL;
  salesProductHeaders = SALES_PRODUCT_EXCEL;
  PointStructureHeaders = POINT_STRUCTURE_EXCEL;
  WhiteListingHeaders = WHITELISTING_EXCEL;
  userHeaders = USER_EXCEL;
  stateHeaders = STATE_EXCEL_HEADER;
  geoHeaders = GEOGRAPHY_EXCEL_HEADER;
  regionHeaders = REGION_EXCEL_HEADER;
  channelHeaders = CHANNEL_EXCEL_HEADER;
  cityHeaders = CITY_EXCEL_HEADER;
  regionUploadHeaders = REGION_UPLOAD_HEADER;
  stateUploadHeaders = STATE_UPLOAD_HEADER;
  cityUploadHeaders = CITY_UPLOAD_HEADER;
  loyaltyProductHeaders = LOYALTY_PRODUCT_HEADER;
  userPointsHeaders = USER_PONITS_HEADER;
  userAssignmentHeaders = USER_ASSIGNMENT_HEADER;
  userCategoryHeader = USER_PONITS_HEADER_KINGSERVICE_CATEGORY;
  userSKUHeader = USER_PONITS_HEADER_KINGSERVICE_SKU;

  pendingOrderDetailHeader = PENDING_ORDER_DETAIL_HEADER;

  constructor(private datePipe: DatePipe) { }

  pipe = new DatePipe("en-US");


  // COMMON FUNCTION, pass headers
  commonPrintHeaders(headers, filename) {
    const finalHeaders = headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('headers', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = headers
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        filename + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }


  //cartan special occerance
  cartanSpecialOcceranceExcel(state , excelHeader ,productHeader,fileName , sheetName1 ,sheetName2 , excelStatic , brandList) {
    debugger
    const finalHeaders = excelHeader;

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetName1, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });


    // static row
        //Add Header Row
        let staticRow = worksheet.addRow(excelStatic);
        // Add Geo Header Row
    
        // Cell Style : Fill and Border
        staticRow.eachCell((cell, number) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFff" },
            bgColor: { argb: "FFFFFF" },
          };
          cell.font = {
            bold: true,
            name: "Calibri",
            size: 10,
          };
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
    
          cell.protection = {
            locked: false,
          };
        });
    


    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

/************Brand WORKSHEET***********************/

let brandWorksheet = workbook.addWorksheet("Brands", {
  pageSetup: {
    horizontalCentered: true,
    verticalCentered: true,
    paperSize: 9,
    orientation: "portrait",
    margins: {
      left: 0.3149606,
      right: 0.3149606,
      top: 0.3543307,
      bottom: 0.3543307,
      header: 0.3149606,
      footer: 0.3149606,
    },
  },
});

//Add Header Row
let brandHeaderRow = brandWorksheet.addRow(productHeader);

// Cell Style : Fill and Border
brandHeaderRow.eachCell((cell, number) => {
  cell.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "9999FF" },
    bgColor: { argb: "FF0000FF" },
  };
  cell.font = {
    bold: true,
    name: "Calibri",
    size: 10,
  };
  cell.alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  cell.border = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };
});

brandWorksheet.columns = [{ header: "Product Type", key: "brand_sku_name" }];

brandList.forEach((d) => {
  let row = brandWorksheet.addRow(d);
  (row.font = {
    name: "Calibri",
    size: 10,
  }),
    (row.alignment = {
      vertical: "middle",
      horizontal: "center",
    });
});

brandWorksheet.columns.forEach(function (column, i) {
  column.width = 26;
});


let brandFormulae = [`${brandWorksheet.name}!A2:A${brandList.length + 1}`];

for (var i = 2; i < 50; i++) {


  worksheet.getCell(`B${i}`).dataValidation = {
    type: "list",
    allowBlank: false,
    formulae: brandFormulae,
    errorStyle: "error",
    errorTitle: "choose a state",
    error: "please select",
    showErrorMessage: true,
  };


}
    


    /************STATE WORKSHEET***********************/

    let stateWorksheet = workbook.addWorksheet(sheetName2, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let stateHeaderRow = stateWorksheet.addRow(productHeader);

    // Cell Style : Fill and Border
    stateHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    stateWorksheet.columns = [{ header: "Product Type", key: "product_type" }];

    state.forEach((d) => {
      let row = stateWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 10,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    stateWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    let stateFormulae = [`${stateWorksheet.name}!A2:A${state.length + 1}`];

    for (var i = 2; i < 50; i++) {


      worksheet.getCell(`G${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: stateFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };


    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        fileName + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }
  // common one sheet header print

  commonOneSheetPrintHeaders(headers, sheetname, filename) {
    const finalHeaders = headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetname, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = headers
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        filename + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }
  // This Function use for print common excel header print 
  commonTwosheetHeaderPrint(header1, header2, sheetName1, sheetName2, filename) {

    const finalHeaders = header1;
    const productHeaders = header2;
    // const filterBrands = brandData.filter((i) => i.is_loyalty == 1);
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetName1, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /****************************PRODUCTS*************************************/

    let productWorksheet = workbook.addWorksheet(sheetName2, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row

    let productHeaderRow = productWorksheet.addRow(header2);

    // Cell Style : Fill and Border
    productHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });



    productWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        filename +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }


  public DownloadstaticReportExcel(name, sheetname, tableData, excelArray) {
    let header = tableData.headers

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetname, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row 
    let headerRow = worksheet.addRow(header);
    worksheet.columns = excelArray;

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    if (tableData.length > 0) {
      tableData.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 10,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });

      });
    }



    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        name +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }


  public DownloadCartonSetpsExcel(name, sheetname, tableData, excelArray,sheetName2, productHeader,product) {
    let header = tableData.headers

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetname, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "629CCA" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Arial",
        size: 10,
        color: { argb: "FFFFFF" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns = excelArray;

    if (tableData.length > 0) {
      tableData.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 10,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });

      });
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /************STATE WORKSHEET***********************/

    let stateWorksheet = workbook.addWorksheet(sheetName2, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let stateHeaderRow = stateWorksheet.addRow(productHeader);

    // Cell Style : Fill and Border
    stateHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    stateWorksheet.columns = [{ header: "Product Type", key: "brand_sku_name" }];

  product.forEach((d) => {
      let row = stateWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 10,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    stateWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    let stateFormulae = [`${stateWorksheet.name}!A2:A${product.length + 1}`];

    for (var i = 2; i < 50; i++) {


      worksheet.getCell(`E${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: stateFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };


    }


    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        name +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }
  public DownloadCrownTargetSetpsExcel(name, sheetname, tableData, excelArray,sheetName2, productHeader,product) {
    let header = tableData.headers

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetname, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "629CCA" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Arial",
        size: 10,
        color: { argb: "FFFFFF" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns = excelArray;

    if (tableData.length > 0) {
      tableData.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 10,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });

      });
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /************STATE WORKSHEET***********************/

    let stateWorksheet = workbook.addWorksheet(sheetName2, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let stateHeaderRow = stateWorksheet.addRow(productHeader);

    // Cell Style : Fill and Border
    stateHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    stateWorksheet.columns = [{ header: "Product Type", key: "brand_sku_name" }];

  product.forEach((d) => {
      let row = stateWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 10,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    stateWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    let stateFormulae = [`${stateWorksheet.name}!A2:A${product.length + 1}`];

    for (var i = 2; i < 50; i++) {


      worksheet.getCell(`C${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: stateFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };


    }


    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        name +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  public DownloadReportExcel(name, tableData, excelArray) {
    let header = tableData.headers

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(name, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "629CCA" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Arial",
        size: 10,
        color: { argb: "FFFFFF" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns = excelArray;

    if (tableData.length > 0) {
      tableData.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 10,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });

      });
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        name +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }



  // Rejected users
  exportRejectedUsersExcel(excelData) {
    console.log('log here', excelData[0].name)
    const finalHeaders = excelData[0].Header;
    // const states = state.map((i) => i.state_name.trim()).join(",");
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Outlet Users', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = excelData[0].column
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    if (excelData[0].Body.length > 0) {
      excelData[0].Body.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 8,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });
      });
    }

    /************Other roles***********************/

    excelData.shift();

    excelData.forEach((userData, i) => {
      //console.log('userdata:', userData.name);
      let otherRoleSheet = workbook.addWorksheet('Sales Users', {
        pageSetup: {
          horizontalCentered: true,
          verticalCentered: true,
          paperSize: 9,
          orientation: "portrait",
          margins: {
            left: 0.3149606,
            right: 0.3149606,
            top: 0.3543307,
            bottom: 0.3543307,
            header: 0.3149606,
            footer: 0.3149606,
          },
        },
      });

      //Add Header Row
      let stateHeaderRow = otherRoleSheet.addRow(userData.Header);

      // Cell Style : Fill and Border
      stateHeaderRow.eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "9999FF" },
          bgColor: { argb: "FF0000FF" },
        };
        cell.font = {
          bold: true,
          name: "Calibri",
          size: 8,
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });

      otherRoleSheet.columns = userData.column;

      userData.Body.forEach((d) => {
        let row = otherRoleSheet.addRow(d);
        (row.font = {
          name: "Calibri",
          size: 8,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });
      });

      otherRoleSheet.columns.forEach(function (column, i) {
        column.width = 26;
      });

    });


    /***************************************************/



    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Migrated_users_status_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }


  exportExcel(brandData: ProductModel[]) {
    const header = this.TargetHeaders;
    const filterBrands = brandData.filter((i) => i.is_loyalty == 1);
    const Brands = filterBrands.map((i) => i.product_name);
    const finalHeaders = header.concat(Brands);
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Target Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B9D0E8" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Target_Excel-Monthly_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportUpdateSKUExcel(brandData: ProductModel[], ReportData: any[]) {
    const header = this.TargetHeaders;
    const filterBrands = brandData.filter((i) => i.is_loyalty == 1);
    const Brands = filterBrands.map((i) => i.product_name);
    const finalHeaders = header.concat(Brands);
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Target SKU Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B9D0E8" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns = [
      { header: 'Outlet Code', key: 'ro_code' }
    ];

    let finalColumns = [];

    brandData.forEach(element => {
      let data: ColumnModel = new ColumnModel;
      data.header = element.product_name;
      data.key = element.product_name + '_target_qty';
      finalColumns.push(data);
    });

    worksheet.columns = worksheet.columns.concat(finalColumns);
    const map = new Map();
    const finalData = [];
    console.log('Total Data Length - ', ReportData.length);
    for (const item of ReportData) {
      if (!map.has(item.ro_code)) {
        map.set(item.ro_code, item);
        let data: OutletModel = new OutletModel;
        let data1: any = {}
        data.ro_code = item.ro_code;
        for (let { product_name } of brandData) {
          let found = ReportData.findIndex(x => x.product_name == product_name && x.ro_code == item.ro_code);
          if (found >= 0) {
            data1[(product_name + '_target_qty')] = ReportData.find(x => x.product_name == product_name && x.ro_code == item.ro_code).target_qty;
          }
        }
        data.targetData = data1;
        finalData.push({ ...data });
        console.log(finalData);
      }
    }

    let rowData = [];

    if (finalData.length > 0) {
      finalData.forEach(i => {
        let merge = { ...i, ...i.targetData };
        rowData.push(merge);
      })
    }

    if (rowData.length > 0) {
      rowData.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {
          name: 'Arial',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Targets_SKU_Excel-Monthly_Report_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportAchievementExcel(brandData: ProductModel[], brandCategorymodel: BrandCategoryModel[]) {
    const header = this.TargetHeaders;
    const filterBrands = brandData.filter((i) => i.is_loyalty == 1);
    const Brands = filterBrands.map((i) => i.product_name);
    const filterBrandCategory = brandCategorymodel;
    const Category = filterBrandCategory.map((i) => i.brand_category_name);
    const finalHeaders = header.concat(Brands);
    const finalHeaders1 = header.concat(Category);
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Sales Achievement Category Wise", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders1);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B9D0E8" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    let worksheet1 = workbook.addWorksheet("Sales Achievement Product Wise", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow1 = worksheet1.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow1.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B9D0E8" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Sales_Achievement_Excel-Monthly_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportCategoryExcel(brandData: BrandCategoryModel[]) {
    const header = this.TargetHeaders;
    const filterBrands = brandData;
    const Brands = filterBrands.map((i) => i.brand_category_name);
    const finalHeaders = header.concat(Brands);
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Target Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B9D0E8" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Target_Excel-Monthly_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportUpdateCategoryExcel(brandData: BrandCategoryModel[], ReportData) {
    const header = this.TargetHeaders;
    const filterBrands = brandData;
    const Brands = filterBrands.map((i) => i.brand_category_name);
    const finalHeaders = header.concat(Brands);
    debugger
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Target Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B9D0E8" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns = [
      { header: 'Outlet Code', key: 'ro_code' }
    ];

    let finalColumns = [];

    brandData.forEach(element => {
      let data: ColumnModel = new ColumnModel;
      data.header = element.brand_category_name;
      data.key = element.brand_category_name + '_target_qty';
      finalColumns.push(data);
    });

    worksheet.columns = worksheet.columns.concat(finalColumns);

    const map = new Map();
    const finalData = [];
    console.log('Total Data Length - ', ReportData.length);
    for (const item of ReportData) {
      if (!map.has(item.ro_code)) {
        map.set(item.ro_code, item);
        let data: OutletModel = new OutletModel;
        let data1: any = {}
        data.ro_code = item.ro_code;
        for (let { brand_category_name } of brandData) {
          let found = ReportData.findIndex(x => x.brand_category_name == brand_category_name && x.ro_code == item.ro_code);
          if (found >= 0) {
            data1[(brand_category_name + '_target_qty')] = ReportData.find(x => x.brand_category_name == brand_category_name && x.ro_code == item.ro_code).target_qty;
          }
        }
        data.targetData = data1;
        finalData.push({ ...data });
        console.log(finalData);
      }
    }

    let rowData = [];

    if (finalData.length > 0) {
      finalData.forEach(i => {
        let merge = { ...i, ...i.targetData };
        rowData.push(merge);
      })
    }

    if (rowData.length > 0) {
      rowData.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {
          name: 'Arial',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Targets_Category_Excel_Report-Monthly_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportUpdateAchvCategoryExcel(brandData: BrandCategoryModel[], ReportData) {
    const header = this.TargetHeaders;
    const filterBrands = brandData;
    const Brands = filterBrands.map((i) => i.brand_category_name);
    const finalHeaders = header.concat(Brands);
    debugger
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Sales Achievement Category Wise", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B9D0E8" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns = [
      { header: 'Outlet Code', key: 'ro_code' }
    ];

    let finalColumns = [];

    brandData.forEach(element => {
      let data: ColumnModel = new ColumnModel;
      data.header = element.brand_category_name;
      data.key = element.brand_category_name + '_sales_qty';
      finalColumns.push(data);
    });

    worksheet.columns = worksheet.columns.concat(finalColumns);

    const map = new Map();
    const finalData = [];
    console.log('Total Data Length - ', ReportData.length);
    for (const item of ReportData) {
      if (!map.has(item.ro_code)) {
        map.set(item.ro_code, item);
        let data: OutletModel = new OutletModel;
        let data1: any = {}
        data.ro_code = item.ro_code;
        for (let { brand_category_name } of brandData) {
          let found = ReportData.findIndex(x => x.brand_category_name == brand_category_name && x.ro_code == item.ro_code);
          if (found >= 0) {
            data1[(brand_category_name + '_sales_qty')] = ReportData.find(x => x.brand_category_name == brand_category_name && x.ro_code == item.ro_code).sales_qty;
          }
        }
        data.targetData = data1;
        finalData.push({ ...data });
        console.log(finalData);
      }
    }

    let rowData = [];

    if (finalData.length > 0) {
      finalData.forEach(i => {
        let merge = { ...i, ...i.targetData };
        rowData.push(merge);
      })
    }

    if (rowData.length > 0) {
      rowData.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {
          name: 'Arial',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Achievements_Category_Excel_Report-Monthly_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportUpdateAchvCategoryExcelWithTarget(brandData: BrandCategoryModel[], ReportData) {
    const header = this.TargetHeaders;
    const filterBrands = brandData;
    const Brands = filterBrands.map((i) => i.brand_category_name);
    const finalHeaders = header.concat(Brands);
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Achievements Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    const rowheader = ["", "Targets", "Achievement"];
    let row1 = worksheet.addRow(rowheader);

    //Add Header Row
    //let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    row1.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B9D0E8" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns = [
      { header: 'Outlet Code', key: 'ro_code' }
    ];

    let finalColumns = [];

    brandData.forEach(element => {
      let data: ColumnModel = new ColumnModel;
      data.header = element.brand_category_name;
      data.key = element.brand_category_name + '_target_qty';
      finalColumns.push(data);
    });
    worksheet.columns = worksheet.columns.concat(finalColumns);

    let finalColumns2 = [];

    brandData.forEach(element => {
      let data: ColumnModel = new ColumnModel;
      data.header = element.brand_category_name;
      data.key = element.brand_category_name + '_sales_qty';
      finalColumns2.push(data);
    });
    worksheet.columns = worksheet.columns.concat(finalColumns2);

    const map = new Map();
    const finalData = [];
    console.log('Total Data Length - ', ReportData.length);
    for (const item of ReportData) {
      if (!map.has(item.ro_code)) {
        map.set(item.ro_code, item);
        let data: OutletModel = new OutletModel;
        let data1: any = {}
        data.ro_code = item.ro_code;
        for (let { brand_category_name } of brandData) {
          let found = ReportData.findIndex(x => x.brand_category_name == brand_category_name && x.ro_code == item.ro_code);
          if (found >= 0) {
            data1[(brand_category_name + '_target_qty')] = ReportData.find(x => x.brand_category_name == brand_category_name && x.ro_code == item.ro_code).target_qty;
            data1[(brand_category_name + '_sales_qty')] = ReportData.find(x => x.brand_category_name == brand_category_name && x.ro_code == item.ro_code).sales_qty;
          }
        }
        data.targetData = data1;
        finalData.push({ ...data });
        console.log(finalData);
      }
    }

    let rowData = [];

    if (finalData.length > 0) {
      finalData.forEach(i => {
        let merge = { ...i, ...i.targetData };
        rowData.push(merge);
      })
    }

    if (rowData.length > 0) {
      rowData.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {
          name: 'Arial',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );
    }

    // worksheet.columns.forEach(function (column, i) {
    //   column.width = 26;
    // });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Achievements_Category_Excel_Report-Monthly_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportUpdateAchvSKUExcel(brandData: ProductModel[], ReportData: any[]) {
    const header = this.TargetHeaders;
    const filterBrands = brandData.filter((i) => i.is_loyalty == 1);
    const Brands = filterBrands.map((i) => i.product_name);
    const finalHeaders = header.concat(Brands);
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Sales Achievement Product Wise", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B9D0E8" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns = [
      { header: 'Outlet Code', key: 'ro_code' }
    ];

    let finalColumns = [];

    brandData.forEach(element => {
      let data: ColumnModel = new ColumnModel;
      data.header = element.product_name;
      data.key = element.product_name + '_sales_qty';
      finalColumns.push(data);
    });

    worksheet.columns = worksheet.columns.concat(finalColumns);
    const map = new Map();
    const finalData = [];
    console.log('Total Data Length - ', ReportData.length);
    for (const item of ReportData) {
      if (!map.has(item.ro_code)) {
        map.set(item.ro_code, item);
        let data: OutletModel = new OutletModel;
        let data1: any = {}
        data.ro_code = item.ro_code;
        for (let { product_name } of brandData) {
          let found = ReportData.findIndex(x => x.product_name == product_name && x.ro_code == item.ro_code);
          if (found >= 0) {
            data1[(product_name + '_sales_qty')] = ReportData.find(x => x.product_name == product_name && x.ro_code == item.ro_code).sales_qty;
          }
        }
        data.targetData = data1;
        finalData.push({ ...data });
        console.log(finalData);
      }
    }

    let rowData = [];

    if (finalData.length > 0) {
      finalData.forEach(i => {
        let merge = { ...i, ...i.targetData };
        rowData.push(merge);
      })
    }

    if (rowData.length > 0) {
      rowData.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {
          name: 'Arial',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Achievements_SKU_Excel-Monthly_Report_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportAchievementsExcel(
    brandData: BrandModel[],
    loyaltyTarget: LoyaltyTargetModel[]
  ) {
    const finalHeaders = this.salesHeaders;
    const finalProductHeaders = this.salesProductHeaders;
    const achievementHeaders = finalHeaders.concat(brandData)
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Sales Achievement Category Wise", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    let worksheet1 = workbook.addWorksheet("Sales Achievement Product Wise", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "F79494" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns = [
      { header: "Outlet Code", key: "ro_code" },
      { header: "Outlet Name", key: "ro_name" },
      { header: "Mobile No.", key: "user_number" },
      { header: "Category", key: "short_name" },
      { header: "Target Qty", key: "target_qty" },
      { header: "Achievement Qty", key: "" },
    ];

    let headerRow1 = worksheet1.addRow(finalProductHeaders);

    headerRow1.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "F79494" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet1.columns = [
      { header: "Outlet Code", key: "ro_code" },
      { header: "Outlet Name", key: "ro_name" },
      { header: "Mobile No.", key: "user_number" },
      { header: "Product Code", key: "product_code" },
      { header: "Product Name", key: "product_name" },
      { header: "Target Qty", key: "target_qty" },
      { header: "Achievement Qty", key: "" },
    ];

    let productLoyalty = [];

    // loyaltyTarget.forEach((l) => {
    //   if (l.sku_wise_target.length > 0) {
    //     l.sku_wise_target.forEach((s) => {
    //       productLoyalty.push(s);
    //     });
    //   }
    // });

    // loyaltyTarget.forEach((d) => {
    //   if (d.sku_wise_target.length <= 0) {
    //     let row = worksheet.addRow(d);
    //     (row.font = {
    //       name: "Calibri",
    //       size: 8,
    //     }),
    //       (row.alignment = {
    //         vertical: "middle",
    //         horizontal: "center",
    //       });
    //   }
    // });

    // if (productLoyalty.length > 0) {
    //   productLoyalty.forEach((d) => {
    //     let row = worksheet1.addRow(d);
    //     (row.font = {
    //       name: "Calibri",
    //       size: 8,
    //     }),
    //       (row.alignment = {
    //         vertical: "middle",
    //         horizontal: "center",
    //       });
    //   });
    // }
    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Sales_Achievements_Excel-Monthly_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportPointExcel(brandData: BrandCategoryModel[], outletCount: any) {
    const finalHeaders = this.PointStructureHeaders;
    const productHeaders = this.loyaltyProductHeaders;
    const filterBrands = brandData;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Point Structure Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /****************************PRODUCTS*************************************/

    let productWorksheet = workbook.addWorksheet("Loyalty_Products", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let productHeaderRow = productWorksheet.addRow(this.loyaltyProductHeaders);

    // Cell Style : Fill and Border
    productHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    productWorksheet.columns = [
      { header: "Brand Name", key: "crown_brand_name" },
      { header: "Brand Category Name", key: "brand_category_name" },
      { header: "Product Short Name", key: "short_name" },
    ];

    brandData.forEach((d) => {
      let row = productWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    /*************************************************************************/

    let productFormulae = [
      `${productWorksheet.name}!B2:B${filterBrands.length + 1}`,
    ];

    let iterationLength = filterBrands.length + 1;

    if (outletCount > 0) {
      iterationLength = outletCount * filterBrands.length + 1;
    }

    for (var i = 2; i <= iterationLength; i++) {
      worksheet.getCell(`B${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: productFormulae,
        errorStyle: "error",
        errorTitle: "choose a role",
        error: "please select",
        showErrorMessage: true,
      };
    }

    productWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Point_Structure_Excel-Monthly_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportWhiteListExcel(modules: any[]) {
    const header = this.WhiteListingHeaders;
    const modulesHeaders = modules.map((i) => i.module_name);
    const finalHeaders = header.concat(modulesHeaders);
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Whitelisting Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    

    


    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Whitelisting_Excel_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportUserPhoneUpdate() {
    const finalHeaders = USER_PHONE_HEADER;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("User Phone Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "User_Phone_Update_Excel_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }


  exportUserLoginReport(excelData) {
    const finalHeaders = Account_Login_Report;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Account Login Report", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    worksheet.columns = [
      { header: 'User Type', key: 'user_type' },
      { header: 'Outlet/M1/TMM Name', key: 'user_name' },
      { header: 'User Profile Name', key: 'profile_name' },
      { header: 'Device', key: 'device' },
      { header: 'IP Address', key: 'ipaddress' },
      { header: 'Logged In Mobile Number', key: 'phone_number' },
      { header: 'Region', key: 'region_name' },
      { header: 'State', key: 'state_name' },
      { header: 'Login Via (OTP/Password)', key: 'logintype' },
      { header: 'Login Date & Time', key: 'loginat' },
      { header: 'Login Status (Success/Failed)', key: 'login_status' },
    ]

    if (excelData.length > 0) {
      excelData.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {
          name: 'Arial',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Account Login Report_Excel_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportHubRedemptionReport(excelData) {
    const finalHeaders = REDEMPTION_Report;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Redemption Detailed Report", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /**channel_desc: "Counter A"
    dummy_rocode: "000079"
    mobile_no: "9890808349"
    orderid: "ODT0010060"
    outlet_name: "ARADHANA WINES"
    points_redeeem: 60
    prod_name: "Paytm E-Gift Voucher"
    prod_value: 200
    qty: 2
    reason: "Denomination is not available"
    region_name: "Central"
    state_name: "Maharashtra"
    status: "Failed"
    updated_date: "2021-03-01 10:00:01"
    user_id: 3212 */


    worksheet.columns = [
      { header: 'Outlet Name', key: 'outlet_name' },
      { header: 'Classification', key: 'channel_desc' },
      { header: 'CSM Mobile Number', key: 'mobile_no' },
      { header: 'Region', key: 'region_name' },
      { header: 'State', key: 'state_name' },
      { header: 'Order ID', key: 'orderid' },
      { header: 'Redemption Date', key: 'updated_date' },
      { header: 'Product/Voucher Name', key: 'prod_name' },
      { header: 'QTY', key: 'qty' },
      { header: 'Product/Voucher Value', key: 'prod_value' },
      { header: 'Points Redeemed', key: 'points_redeeem' },
    ]

    if (excelData.length > 0) {
      excelData.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {
          name: 'Arial',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Redemption Detailed Report_Excel_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportPointTransactionDetailedReport(excelData) {
    const finalHeaders = POINT_TRANSACTION_REPORT;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Point Transaction Detailed Report", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    //     balance_points: 13
    // channel_desc: "Walk-In"
    // description: "Points earned by completing Social Distancing Guidelines in Content & Learning Module"
    // dummy_rocode: "100956"
    // m1_name: "AKARSH.BS"
    // m2_name: "NAKUL MALHOTRA"
    // m3_name: "SHISHIR GAUR"
    // mobile_no: "9743909013"
    // outlet_name: "S L N S WINE PARADISE"
    // points_burned: null
    // points_earned: 13
    // region_name: "South"
    // state_name: "Karnataka"
    // transaction_date: "2020-07-24 11:47:58"
    // transaction_id: 355
    // transaction_month: "Jul-2020"
    // transaction_type: "Content"
    // user_id: 950


    worksheet.columns = [
      { header: 'Osmosys Code', key: 'dummy_rocode' },
      { header: 'Outlet Name', key: 'outlet_name' },
      { header: 'Channel', key: 'channel_desc' },
      { header: 'CSM Mobile Number', key: 'mobile_no' },
      { header: 'Region', key: 'region_name' },
      { header: 'State', key: 'state_name' },
      { header: 'M1 Name', key: 'm1_name' },
      { header: 'M2 Name', key: 'm2_name' },
      { header: 'M3 Name', key: 'm3_name' },
      { header: 'Transaction ID', key: 'transaction_id' },
      { header: 'Transaction Type', key: 'transaction_type' },
      { header: 'Descriptions', key: 'description' },
      { header: 'Transaction Month', key: 'transaction_month' },
      { header: 'Transaction Date', key: 'transaction_date' },
      { header: 'Points Earned', key: 'points_earned' },
      { header: 'Points Burned', key: 'points_burned' },
    ]

    if (excelData.length > 0) {
      excelData.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {
          name: 'Arial',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Point Transaction Detailed Report_Excel_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportCSMMobileNumberReport(excelData) {
    const finalHeaders = CSM_Mobile_Number_Change_Log_Report;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Users Changes Log Report", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    worksheet.columns = [
      { header: 'Osmosys Codes', key: 'ro_code' },
      { header: 'Change Request', key: 'record_type' },
      { header: 'Previous Record', key: 'exisiting_record' },
      { header: 'Changed Record', key: 'updated_record' },
      { header: 'Changed Date', key: 'modified_date' }
    ]

    if (excelData.length > 0) {
      excelData.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {
          name: 'Arial',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "User Changes Log Report_Excel_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportEnrollementDetailReport(excelData) {
    const finalHeaders = Enrollement_Detailed_Report;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Enrollement Detailed Report", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    worksheet.columns = [
      { header: 'Osmosys Code', key: 'osmosys_code' },
      { header: 'Outlet Name', key: 'outlet_name' },
      { header: 'Enrolled Date', key: 'enrolled_date' },
      { header: 'Channel', key: 'channel_desc' },
      { header: 'Region', key: 'region_name' },
      { header: 'State', key: 'state_name' },
      { header: 'Total Points Earned', key: 'points_earned' },
      { header: 'Total Points Redeemed', key: 'points_redeemed' },
      { header: 'Total Points Balance', key: 'balance_points' },
      { header: 'CSM Name', key: 'csm_name' },
      { header: 'CSM Mobile Number', key: 'csm_mobile' },
      { header: 'M1 Name', key: 'm1_name' },
      { header: 'M1 Mobile Number', key: 'm1_mobile' },
      { header: 'M2 Name', key: 'm2_name' },
      { header: 'M2 Mobile Number', key: 'm2_mobile' },
      { header: 'M3 Name', key: 'm3_name' },
      { header: 'M3 Mobile Number', key: 'm3_mobile' },
      { header: 'TMM Name', key: 'tmm_name' },
      { header: 'TMM Mobile Number', key: 'tmm_mobile' },
      { header: 'Enrollment Status (Active/Inactive)', key: 'active_status' },
    ]

    if (excelData.length > 0) {
      excelData.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {
          name: 'Arial',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Enrollement Detailed Report_Excel_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }


  exportUserExcel(
    geography: GeographyModel[],
    channel: ChannelModel[],
    region: RegionModel[],
    role: UserRoleModel[],
    state: any[],
    city: any[]
  ) {
    const finalHeaders = this.userHeaders;
    const geographies = geography.map((i) => i.geograph_name.trim()).join(",");
    const channels = channel.map((i) => i.channel_desc.trim()).join(",");
    const regions = region.map((i) => i.region_name.trim()).join(",");
    const roles = role.map((i) => i.role_name.trim()).join(",");
    const states = state.map((i) => i.state_name.trim()).join(",");

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Users", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /******GEO WORKSHEET********/
    let geographyWorksheet = workbook.addWorksheet("Geography", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let geoHeaderRow = geographyWorksheet.addRow(this.geoHeaders);

    // Cell Style : Fill and Border
    geoHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    geographyWorksheet.columns = [
      { header: "Geography", key: "geograph_name" },
    ];

    geography.forEach((d) => {
      let row = geographyWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    geographyWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /******GEO WORKSHEET********/

    /************STATE WORKSHEET***********************/

    let stateWorksheet = workbook.addWorksheet("States", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let stateHeaderRow = stateWorksheet.addRow(this.stateHeaders);

    // Cell Style : Fill and Border
    stateHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    stateWorksheet.columns = [{ header: "States", key: "state_name" }];

    state.forEach((d) => {
      let row = stateWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    stateWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /***************************************************/

    /************CHANNEL WORKSHEET***********************/

    let channelWorksheet = workbook.addWorksheet("Channels", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let channelHeaderRow = channelWorksheet.addRow(this.channelHeaders);

    // Cell Style : Fill and Border
    channelHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    channelWorksheet.columns = [{ header: "Channels", key: "channel_desc" }];

    channel.forEach((d) => {
      let row = channelWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    channelWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /***************************************************/

    /************REGION WORKSHEET***********************/

    let regionWorksheet = workbook.addWorksheet("Regions", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let regionHeaderRow = regionWorksheet.addRow(this.regionHeaders);

    // Cell Style : Fill and Border
    regionHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    regionWorksheet.columns = [{ header: "Regions", key: "region_name" }];

    region.forEach((d) => {
      let row = regionWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    regionWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /***************************************************/


    // Specify list of valid values (One, Two, Three, Four).
    // Excel will provide a dropdown with these values.

    let rolesData = this.excelDataValidationSplit(roles);

    let GeoFormulae = [`${geographyWorksheet.name}!A2:A${geography.length}`];

    let stateFormulae = [`${stateWorksheet.name}!A2:A${state.length}`];

    let regionFormulae = [`${regionWorksheet.name}!A2:A${region.length}`];


    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`B${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: rolesData,
        errorStyle: "error",
        errorTitle: "choose a role",
        error: "please select",
        showErrorMessage: true,
      };

      worksheet.getCell(`E${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: GeoFormulae,
        errorStyle: "error",
        errorTitle: "choose a geography",
        error: "please select",
        showErrorMessage: true,
      };
      worksheet.getCell(`C${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: regionFormulae,
        promptTitle: "Region",
        prompt: "Select any region",
        showErrorMessage: true,
      };

      worksheet.getCell(`H${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: stateFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Add_User_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }


  exportMysteryQuestionBankExcel() {
    const finalHeaders = MYSTERY_QUESTION_BANK;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Question Bank Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let ModuleWorksheet = workbook.addWorksheet("Types", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    let modulearray = ["Type"];

    let modulesHeaders = ModuleWorksheet.addRow(modulearray);

    modulesHeaders.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    ModuleWorksheet.columns = [{ header: "Type", key: "name" }];

    let type = [
      { "id": 1, "name": "Yes or No" },
      { "id": 2, "name": "Choice" },
      { "id": 3, "name": "Text" },
      { "id": 4, "name": "Rating" },
      { "id": 5, "name": "Image" },
    ];

    type.forEach((d) => {
      let row = ModuleWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    })

    ModuleWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    let moduleFormulae = [`${ModuleWorksheet.name}!A2:A${type.length + 1}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`A${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: moduleFormulae,
        errorStyle: "error",
        errorTitle: "choose a module",
        error: "please select",
        showErrorMessage: true,
      };
    }
    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Question_Bank_Excel_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportMysteryUserExcel(users) {
    const finalHeaders = MYSTERY_USER_ASSIGNMENT_HEADER;

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Mystery Shopper Assignment", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let ModuleWorksheet = workbook.addWorksheet("Users", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    let modulearray = ["Mystery Shopper", "Mobile"];

    let modulesHeaders = ModuleWorksheet.addRow(modulearray);

    modulesHeaders.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    ModuleWorksheet.columns = [{ header: "Mystery Shopper", key: "user_detail_name" }, { header: "Mobile", key: "username" }];


    users.forEach((d) => {
      let row = ModuleWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    })

    ModuleWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    let moduleFormulae = [`${ModuleWorksheet.name}!B2:B${users.length + 1}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`A${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: moduleFormulae,
        errorStyle: "error",
        errorTitle: "choose a module",
        error: "please select",
        showErrorMessage: true,
      };
    }
    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "MysteryShopper_Outlet_Assigment_Excel_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }



  excelDataValidationSplit(item) {
    let data = item.split(",").join(",");
    data = '"' + data + '"';
    return [`${data}`];
  }

  /**
   * Kailash_dev
   * Brand list export
   */
  exportBrandExcel(headers, title, dropDown) {
    console.log(dropDown);
    const finalHeaders = headers;
    let categoryHeader = ["Category"];
    let subCategoryHeader = ["Sub Category"];
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Add New Details", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      // cell.protection = { locked: true };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /*********************category Starts**********************************/
    /** adding category drop down */
    let categoryWorksheet = workbook.addWorksheet("Category", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let categoryHeaderRow = categoryWorksheet.addRow(categoryHeader);

    // Cell Style : Fill and Border
    categoryHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    categoryWorksheet.columns = [{ header: "Category", key: "category_name" }];

    dropDown.category.forEach((d) => {
      let row = categoryWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    categoryWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let categoryFormulae = [
      `${categoryWorksheet.name}!A2:A${dropDown.category.length}`,
    ];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`H${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: categoryFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
    }

    /**************************************************************/

    let subCategoryWorksheet = workbook.addWorksheet("Sub_Category", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let subCategoryHeaderRow = subCategoryWorksheet.addRow(subCategoryHeader);

    // Cell Style : Fill and Border
    subCategoryHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    subCategoryWorksheet.columns = [
      { header: "Sub Category", key: "sub_category_name" },
    ];

    dropDown.subCategory.forEach((d) => {
      let row = subCategoryWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    subCategoryWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let subCategoryFormulae = [
      `${subCategoryWorksheet.name}!A2:A${dropDown.subCategory.length}`,
    ];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`K${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: subCategoryFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        title + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  exportRegionExcel() {
    const finalHeaders = this.regionUploadHeaders;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Region Add Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Region_Excel_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportUserPointExcel(module, categoryList, skuList) {
    console.log('here::::', module)
    const finalHeaders = this.userPointsHeaders;
    const categoryHeaders = this.userCategoryHeader;
    const skuHeaders = this.userSKUHeader;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("User Point Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    // kjdskjds

    let category = workbook.addWorksheet("KingService via Category", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let kingserviceHeader = category.addRow(categoryHeaders);
    // Cell Style : Fill and Border
    kingserviceHeader.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    category.columns.forEach(function (column, i) {
      column.width = 26;
    });


    let sku = workbook.addWorksheet("KingService via SKU", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let kingserviceSKUHeader = sku.addRow(skuHeaders);
    // Cell Style : Fill and Border
    kingserviceSKUHeader.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    sku.columns.forEach(function (column, i) {
      column.width = 26;
    });
    // sdfkjsdkfjndsk

    let ModuleWorksheet = workbook.addWorksheet("Modules", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    let modulearray = ["Module Name"];

    let modulesHeaders = ModuleWorksheet.addRow(modulearray);

    modulesHeaders.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    ModuleWorksheet.columns = [{ header: "Module Name", key: "name" }];

    module.forEach((d) => {
      let row = ModuleWorksheet.addRow(d);
    })

    ModuleWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let PointTypeWorksheet = workbook.addWorksheet("Point_Type", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    let pointArray = ["Type"];

    let pointTypeHeaders = PointTypeWorksheet.addRow(pointArray);

    pointTypeHeaders.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });


    let type = [
      { "id": 1, "name": "Credit" },
      { "id": 2, "name": "Debit" },
    ];


    PointTypeWorksheet.columns = [{ header: "Type", key: "name" }];

    type.forEach((d) => {
      let row = PointTypeWorksheet.addRow(d);
    })

    PointTypeWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    // dslfkmlks

    let category_name = workbook.addWorksheet("Cotegory", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    let Categoryarray = ["Category Name"];

    let CategoryHeader = category_name.addRow(Categoryarray);

    CategoryHeader.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    category_name.columns = [{ header: "Category Name", key: "brand_category_name" }];

    categoryList.forEach((d) => {
      let row = category_name.addRow(d);
    })

    category_name.columns.forEach(function (column, i) {
      column.width = 26;
    });


    let sku_name = workbook.addWorksheet("SKUName", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    let skuarray = ["SKUName"];

    let skuHeader = sku_name.addRow(skuarray);

    skuHeader.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    sku_name.columns = [{ header: "SKUName", key: "product_name" }];

    skuList.forEach((d) => {
      let row = sku_name.addRow(d);
    })

    sku_name.columns.forEach(function (column, i) {
      column.width = 50;
    });

    // jdfkjs

    let PointTypeFormulae = [`${PointTypeWorksheet.name}!A2:A${type.length + 1}`];

    let moduleFormulae = [`${ModuleWorksheet.name}!A2:A${module.length + 1}`];

    let skumodule = [`${sku_name.name}!A2:A${skuList.length + 1}`];

    let categorymodule = [`${category_name.name}!A2:A${categoryList.length + 1}`];


    let kingServicemodule = [`${ModuleWorksheet.name}!A2:A2`];




    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`B${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: moduleFormulae,
        errorStyle: "error",
        errorTitle: "choose a module",
        error: "please select",
        showErrorMessage: true,
      };
    }

    for (var i = 2; i < 50; i++) {
      category.getCell(`B${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: kingServicemodule,
        errorStyle: "error",
        errorTitle: "choose a module",
        error: "please select",
        showErrorMessage: true,
      };
    }

    for (var i = 2; i < 50; i++) {
      sku.getCell(`B${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: kingServicemodule,
        errorStyle: "error",
        errorTitle: "choose a module",
        error: "please select",
        showErrorMessage: true,
      };
    }

    for (var i = 2; i < 50; i++) {
      sku.getCell(`G${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: skumodule,
        errorStyle: "error",
        errorTitle: "choose a module",
        error: "please select",
        showErrorMessage: true,
      };
    }

    for (var i = 2; i < 50; i++) {
      category.getCell(`G${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: categorymodule,
        errorStyle: "error",
        errorTitle: "choose a module",
        error: "please select",
        showErrorMessage: true,
      };
    }
    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`D${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: PointTypeFormulae,
        errorStyle: "error",
        errorTitle: "choose",
        error: "please select",
        showErrorMessage: true,
      };
    }
    for (var i = 2; i < 50; i++) {
      category.getCell(`D${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: PointTypeFormulae,
        errorStyle: "error",
        errorTitle: "choose",
        error: "please select",
        showErrorMessage: true,
      };
    }
    for (var i = 2; i < 50; i++) {
      sku.getCell(`D${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: PointTypeFormulae,
        errorStyle: "error",
        errorTitle: "choose",
        error: "please select",
        showErrorMessage: true,
      };
    }
    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "User_Point_Excel_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  orderDetails(excelData) {
    const finalHeaders = excelData[0].headers;
    // const states = state.map((i) => i.state_name.trim()).join(",");
    console.log('looking', excelData)
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(excelData[0].name, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    }); //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 9,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      }; cell.protection = {
        locked: false,
      };
    }); worksheet.columns = excelData[0].column
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    }); if (excelData[0].body.length > 0) {
      excelData[0].body.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 8,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });
      });
    } /***************************************************/ // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Pending Order Details_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }


  processOrderDetails(excelData) {
    const finalHeaders = excelData[0].headers;
    // const states = state.map((i) => i.state_name.trim()).join(",");
    console.log('looking', excelData)
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(excelData[0].name, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    }); //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 9,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      }; cell.protection = {
        locked: false,
      };
    }); worksheet.columns = excelData[0].column
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    }); if (excelData[0].body.length > 0) {
      excelData[0].body.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 8,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });
      });
    } /***************************************************/ // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Process Order Details_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  failedOrders(excelData) {
    const finalHeaders = excelData[0].headers;
    // const states = state.map((i) => i.state_name.trim()).join(",");
    console.log('looking', excelData)
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(excelData[0].name, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    }); //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 9,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      }; cell.protection = {
        locked: false,
      };
    }); worksheet.columns = excelData[0].column
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    }); if (excelData[0].body.length > 0) {
      excelData[0].body.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 8,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });
      });
    } /***************************************************/ // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Failed_Order_Details_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  exportUserAssignment() {
    const finalHeaders = this.userAssignmentHeaders;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("User Assignment Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "User_Assignment_Excel_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportStateExcel(region: any[]) {
    const finalHeaders = this.stateUploadHeaders;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("State Add Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /************REGION WORKSHEET***********************/

    let regionWorksheet = workbook.addWorksheet("Regions", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let regionHeaderRow = regionWorksheet.addRow(this.regionHeaders);

    // Cell Style : Fill and Border
    regionHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    regionWorksheet.columns = [{ header: "Regions", key: "region_name" }];

    region.forEach((d) => {
      let row = regionWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    regionWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /**************************************************************/

    let regionFormulae = [`${regionWorksheet.name}!A2:A${region.length}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`B${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: regionFormulae,
        errorStyle: "error",
        errorTitle: "choose a role",
        error: "please select",
        showErrorMessage: true,
      };
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "State_Excel_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  exportCityExcel(state: any[]) {
    const finalHeaders = this.cityUploadHeaders;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("City Add Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /**************************************************************/

    let stateWorksheet = workbook.addWorksheet("States", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let stateHeaderRow = stateWorksheet.addRow(this.stateHeaders);

    // Cell Style : Fill and Border
    stateHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    stateWorksheet.columns = [{ header: "States", key: "state_name" }];

    state.forEach((d) => {
      let row = stateWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    stateWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let stateFormulae = [`${stateWorksheet.name}!A2:A${state.length}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`B${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: stateFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "City_Excel_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  /**
   * Kailash_dev
   * Brand list export
   */
  exportRewardsExcel(headers, title, dropDown) {
    console.log(dropDown);
    const finalHeaders = headers;
    let categoryHeader = ["Category"];
    let subCategoryHeader = ["Sub Category"];
    let brandHeader = ["Brands"];
    let priceHeader = ["Price"];
    let productHeader = ["Product Types"];
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Add New Details", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      // cell.protection = { locked: true };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /*********************category Starts**********************************/
    /** adding category drop down */
    let categoryWorksheet = workbook.addWorksheet("Category", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let categoryHeaderRow = categoryWorksheet.addRow(categoryHeader);

    // Cell Style : Fill and Border
    categoryHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    categoryWorksheet.columns = [{ header: "Category", key: "category_name" }];

    dropDown.category.forEach((d) => {
      let row = categoryWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    categoryWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let categoryFormulae = [
      `${categoryWorksheet.name}!A2:A${dropDown.category.length + 1}`,
    ];

    /**************************************************************/

    let subCategoryWorksheet = workbook.addWorksheet("Sub_Category", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let subCategoryHeaderRow = subCategoryWorksheet.addRow(subCategoryHeader);

    // Cell Style : Fill and Border
    subCategoryHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    subCategoryWorksheet.columns = [
      { header: "Sub Category", key: "sub_category_name" },
    ];

    dropDown.subCategory.forEach((d) => {
      let row = subCategoryWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    subCategoryWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let subCategoryFormulae = [
      `${subCategoryWorksheet.name}!A2:A${dropDown.subCategory.length + 1}`,
    ];

    /**************************** Brands **********************************/

    let brandWorksheet = workbook.addWorksheet("Brands", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let brandHeaderRow = brandWorksheet.addRow(brandHeader);

    // Cell Style : Fill and Border
    brandHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    brandWorksheet.columns = [{ header: "Brands", key: "brand_name" }];

    dropDown.brands.forEach((d) => {
      let row = brandWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    brandWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let brandFormulae = [
      `${brandWorksheet.name}!A2:A${dropDown.brands.length + 1}`,
    ];

    /**************************** Price Drop down **********************************/
    let priceWorksheet = workbook.addWorksheet("Price_Types", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let priceHeaderRow = priceWorksheet.addRow(priceHeader);

    // Cell Style : Fill and Border
    priceHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    priceWorksheet.columns = [{ header: "Price", key: "price_type" }];

    dropDown.price_type.forEach((d) => {
      let row = priceWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    priceWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let priceFormulae = [
      `${priceWorksheet.name}!A2:A${dropDown.price_type.length + 1}`,
    ];

    /**********************Product types***********************************/

    let productWorksheet = workbook.addWorksheet("Product_Types", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let productHeaderRow = productWorksheet.addRow(productHeader);

    // Cell Style : Fill and Border
    productHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    productWorksheet.columns = [
      { header: "Product Types", key: "product_type" },
    ];

    dropDown.product_types.forEach((d) => {
      let row = productWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    productWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let productFormulae = [
      `${productWorksheet.name}!A2:A${dropDown.product_types.length}`,
    ];

    /**Adding drop into cells */
    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`E${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: categoryFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };

      worksheet.getCell(`F${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: subCategoryFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };

      worksheet.getCell(`G${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: brandFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
      worksheet.getCell(`H${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: priceFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
      worksheet.getCell(`B${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: productFormulae,
        errorStyle: "error",
        errorTitle: "choose a Product",
        error: "please select",
        showErrorMessage: true,
      };
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        title + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }


  exportRedmiptionExcel(excelData) {
    const header = REDEMPTION_MASTER;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Redemptions', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'BEBEBE' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.font = {
        bold: true,
        name: 'Arial',
        size: 10,
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    }
    );
    worksheet.columns = [
      { header: 'User ID', key: 'user_id' },
      { header: 'Name', key: 'mobile_no' },
      { header: 'Mobile.', key: 'customer_name' },
      { header: 'Outlet Code', key: 'outlet_code' },
      { header: 'Outlet Name', key: 'outlet_name' },
      { header: 'Address', key: 'address' },
      { header: 'State', key: 'state_name' },
      { header: 'Created Date', key: 'created_date' },
      { header: 'Status', key: 'status' },
      { header: 'Order Ref No.', key: 'order_refno' },
      { header: 'Product Name', key: 'prod_name' },
      { header: 'Denomination', key: 'denominations' },
      { header: 'Offer Code', key: 'offer_code' },
      { header: 'Offer Code Pin', key: 'offercode_pin' },
      { header: 'Response OrderID', key: 'response_orderid' },
      { header: 'Redeem Points', key: 'redeem_points' },
    ]

    excelData.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = {
        name: 'Arial',
        size: 10,
      },
        row.alignment = {
          vertical: 'middle', horizontal: 'center'
        };
    }
    );
    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Redemption Master - Monthly_' + this.datePipe.transform(new Date(), 'medium') + '.xlsx');
    })
  }


  exportTargetAndAchivementExcel(excelData) {
    const header = TARGET_AND_ACHIVEMENTS;
    const header1 = ["", "", "", "", "", "", "", "",
      "",
      "Achievement",
      'Points'];
    const headerd = [
      "Sl No",
      "Osmosys Code",
      "Outlet Name",
      "TSE",
      "ASM",
      "State",
      "Channel",
      "Name of CSM",
      "MobileNo.",
    ];
    let sku_headers = excelData.sku_headers.map(x => x.product_name);
    sku_headers.push('Total')
    let length = sku_headers.length;
    let count = sku_headers.length;
    var d = Array(3).fill(sku_headers)
    var merged = [].concat.apply([], d)
    let sku_header = headerd.concat(merged)
    console.log(sku_header)
    let d_keys = [];
    let sku_each = excelData.sku_wise
    let sku_total = [...sku_each];
    sku_total.forEach((e, i) => {
      e['slno'] = i + 1;
      for (const [key, value] of Object.entries(e)) {
        console.log(`${key}: ${value}`);
        if (key == 'target' || key == 'achievement' || key == 'points') {
          for (const [keys, values] of Object.entries(e[key])) {
            if (key == 'target') {
              let s = e[key][keys]
              let p = e[key]

              if (keys == 'total_target') {
                let zt = key + '_' + keys
                e[key + '_' + keys] = s;
                if (d_keys.includes(zt)) {
                  console.log('Already')
                }
                else {
                  d_keys.push(zt);
                }

              }
              else {
                let sk = s.product_name + '_' + key
                e[sk] = s.target_qty;
                if (d_keys.includes(sk)) {
                  console.log('Already')
                }
                else {
                  d_keys.push(sk);
                }

              }

            }
            else if (key == 'achievement') {
              let s = e[key][keys]
              if (keys == 'total_achievement') {
                e[key + '_' + keys] = s;
                let za = key + '_' + keys;
                if (d_keys.includes(za)) {
                  console.log('Already')
                }
                else {
                  d_keys.push(za);
                }
              }
              else {
                let sk = s.product_name + '_' + key
                e[sk] = s.acheived_qty;
                if (d_keys.includes(sk)) {
                  console.log('Already')
                }
                else {
                  d_keys.push(sk);
                }
              }
            }
            else {
              let s = e[key][keys]
              if (keys == 'total_points') {
                e[key + '_' + keys] = s;
                let zp = key + '_' + keys;
                if (d_keys.includes(zp)) {
                  console.log('Already')
                }
                else {
                  d_keys.push(zp);
                }
              }
              else {
                let sk = s.product_name + '_' + key
                e[sk] = s.acheived_points;
                if (d_keys.includes(sk)) {
                  console.log('Already')
                }
                else {
                  d_keys.push(sk);
                }
              }
            }

          }
        }

      }
      console.log(sku_total);
    })
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Outlet Wise Targets', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });

    let worksheets = workbook.addWorksheet('Sku Wise', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
    let headerRow1 = worksheet.addRow(header1);
    worksheet.getCell('J1').value = 'Target';
    worksheet.getCell('P1').value = 'Achievement';
    worksheet.getCell('U1').value = 'Points';
    worksheet.mergeCells('J1:P1');
    worksheet.mergeCells('Q1:W1');
    worksheet.mergeCells('X1:AD1');

    let headerRow2 = worksheets.addRow(header1);
    let t_cell = this.numToAlpha(9) + '1'
    let tm_cell = this.numToAlpha(9 + (count - 1)) + '1'
    worksheets.getCell(t_cell).value = 'Target';
    worksheets.mergeCells(t_cell + ':' + tm_cell);
    let a_cell = this.numToAlpha(9 + count) + '1'
    let am_cell = this.numToAlpha(9 + count + (count - 1)) + '1'
    worksheets.getCell(a_cell).value = 'Achievement';
    worksheets.mergeCells(a_cell + ':' + am_cell);
    let p_cell = this.numToAlpha(9 + count + count) + '1'
    let pm_cell = this.numToAlpha(9 + count + count + (count - 1)) + '1'
    worksheets.getCell(p_cell).value = 'Points';
    worksheets.mergeCells(p_cell + ':' + pm_cell);

    headerRow1.eachCell((cell, number) => {
      if (number <= 7) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '99CC00' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      else if (number >= 8 && number <= 9) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'B9D0E8' },
          bgColor: { argb: 'FF0000FF' },

        }
      }
      else if (number >= 10 && number <= 16) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '9999FF' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      else if (number >= 17 && number <= 23) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '90EE90' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      else if (number >= 24 && number <= 31) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F5F5F5' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      cell.font = {
        bold: true,
        name: 'Calibri',
        size: 9,
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    }
    );


    headerRow2.eachCell((cell, number) => {
      if (number <= 7) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '99CC00' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      else if (number >= 8 && number <= 9) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'B9D0E8' },
          bgColor: { argb: 'FF0000FF' },

        }
      }
      else if (number >= 10 && number <= 10 + length) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '9999FF' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      else if (number >= 10 + length && number <= 10 + length + length) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '90EE90' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      else if (number >= 10 + length + length && number <= 10 + length + length + length) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F5F5F5' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      cell.font = {
        bold: true,
        name: 'Calibri',
        size: 9,
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    }
    );
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      if (number <= 7) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '99CC00' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      else if (number >= 8 && number <= 9) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'B9D0E8' },
          bgColor: { argb: 'FF0000FF' },

        }
      }
      else if (number >= 10 && number <= 16) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '9999FF' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      else if (number >= 17 && number <= 23) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '90EE90' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      else if (number >= 24 && number <= 31) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F5F5F5' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      cell.font = {
        bold: true,
        name: 'Calibri',
        size: 9,
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    }
    );




    let headerRows = worksheets.addRow(sku_header);
    headerRows.eachCell((cell, number) => {


      if (number <= 7) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '99CC00' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      else if (number >= 8 && number <= 9) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'B9D0E8' },
          bgColor: { argb: 'FF0000FF' },

        }
      }
      else if (number >= 10 && number < 10 + length) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '9999FF' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      else if (number >= 10 + length && number < 10 + length + length) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '90EE90' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      else if (number >= 10 + length + length && number <= 10 + length + length + length) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F5F5F5' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      cell.font = {
        bold: true,
        name: 'Calibri',
        size: 9,
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    }
    );
    worksheet.columns = [
      { header: '', key: 'sl_no' },
      { header: '', key: 'ro_code' },
      { header: '', key: 'ro_name' },
      { header: '', key: 'tse_name' },
      { header: '', key: 'asm_name' },
      { header: '', key: 'state_name' },
      { header: '', key: 'channel_desc' },
      { header: '', key: 'csm_name' },
      { header: '', key: 'mobile_no' },
      { header: 'Haywards/Knockout', key: 'core_target_qty' },
      { header: 'Beck\'s Ice', key: 'becks_target_qty' },
      { header: 'Budweiser', key: 'premium_target_qty' },
      { header: 'Magnum', key: 'magnum_target_qty' },
      { header: 'Corona/Hoegaarden', key: 'ipb_target_qty' },
      { header: 'Seven Rivers', key: 'innovation_target_qty' },
      { header: 'Target', key: 'total_target_qty' },
      { header: 'Haywards/Knockout', key: 'core_achieved_qty' },
      { header: 'Beck\'s Ice', key: 'becks_achieved_qty' },
      { header: 'Budweiser', key: 'premium_achieved_qty' },
      { header: 'Magnum', key: 'magnum_achieved_qty' },
      { header: 'Corona/Hoegaarden', key: 'ipb_achieved_qty' },
      { header: 'Seven Rivers', key: 'innovation_achieved_qty' },
      { header: 'Achievement', key: 'total_achieved_qty' },
      { header: 'Haywards/Knockout', key: 'core_points' },
      { header: 'Beck\'s Ice', key: 'becks_points' },
      { header: 'Budweiser', key: 'premium_points' },
      { header: 'Magnum', key: 'magnum_points' },
      { header: 'Corona/Hoegaarden', key: 'ipb_points' },
      { header: 'Seven Rivers', key: 'innovation_points' },
      { header: 'Points', key: 'total_points' }
    ];



    worksheets.columns = [
      { header: '', key: 'slno' },
      { header: '', key: 'ro_code' },
      { header: '', key: 'ro_name' },
      { header: '', key: 'tse_name' },
      { header: '', key: 'asm_name' },
      { header: '', key: 'state_name' },
      { header: '', key: 'channel_desc' },
      { header: '', key: 'user_detail_name' },
      { header: '', key: 'username' },
    ]

    d_keys.forEach((e) => {
      let newColumn = worksheets.getColumn(worksheets.columns.length + 1);
      newColumn.key = e
      // worksheets.columns.push(newColumn);
      console.log(worksheets.columns)
    })

    excelData.category_wise.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = {
        name: 'Arial',
        size: 10,
      },
        row.alignment = {
          vertical: 'middle', horizontal: 'center'
        };
    }
    );


    excelData.sku_wise.forEach(d => {

      let row = worksheets.addRow(d);
      row.font = {
        name: 'Arial',
        size: 10,
      },
        row.alignment = {
          vertical: 'middle', horizontal: 'center'
        };
    }
    );
    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'T&A Master Monthly Report' + '.xlsx');
    })
  }


  numToAlpha(num) {

    var alpha = '';

    for (; num >= 0; num = parseInt((num / 26).toString(), 10) - 1) {
      alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
    }



    return alpha;
  }

  alphaToNum(alpha) {

    var i = 0,
      num = 0,
      len = alpha.length;

    for (; i < len; i++) {
      num = num * 26 + alpha.charCodeAt(i) - 0x40;
    }

    return num - 1;
  }


  exportLivePointsExcel(excelData) {

    const header = LIVEPOINT;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Live Points', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'B9D0E8' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.font = {
        bold: true,
        name: 'Calibri',
        size: 11,
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    }
    );

    worksheet.columns = [
      { header: 'SN', key: 'sl_no' },
      { header: 'Osmosys Code', key: 'ro_code' },
      { header: 'Outlet Name', key: 'ro_name' },
      { header: 'State', key: 'state_name' },
      { header: 'Mobile Number', key: 'username' },
      { header: 'Earned Points', key: 'earned_points' },
      { header: 'Redeemed Points', key: 'redeemd_points' },
      { header: 'Unredeemed (LIVE) Points', key: 'live_points' },
      // { header: 'User Status',key: 'user_status'},
    ]

    let totalEarnedPoints = 0;
    let totalRedemePoints = 0;
    let totalLivePoints = 0;

    excelData.forEach(d => {
      let row = worksheet.addRow(d);
      let qty = row.getCell(6);
      let qty1 = row.getCell(7);
      let qty2 = row.getCell(8);
      if (+qty.value) {
        totalEarnedPoints += +qty.value;
      }
      if (+qty1.value) {
        totalRedemePoints += +qty1.value;
      }
      if (+qty2.value) {
        totalLivePoints += +qty2.value;
      }
      row.font = {
        name: 'Calibri',
        size: 11,
      },
        row.alignment = {
          vertical: 'middle', horizontal: 'center'
        };
    }
    );
    let finalHeader = ["", "", "", "", "", "Total", "", "", ""];
    let lastRow = worksheet.addRow(finalHeader);
    lastRow.eachCell((cell, number) => {
      if (number == 4) {
        cell.value = "Total";
      }
      else if (number == 6) {
        cell.value = totalEarnedPoints;
      }
      else if (number == 7) {
        cell.value = totalRedemePoints;
      }
      else if (number == 8) {
        cell.value = totalLivePoints;
      }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'B9D0E8' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.font = {
        bold: true,
        name: 'Calibri',
        size: 11,
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    }
    );
    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Live Points - Monthly_' + this.datePipe.transform(new Date(), 'medium') + '.xlsx');
    })
  }

  DAUTimeSpentExcel(excelData) {
    const header = FIRE_TIME_SPENT;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('DAU Time Spent', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0000' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.font = {
        bold: true,
        name: 'Arial',
        size: 10,
        color: { argb: 'FFFFFF' }
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    }
    );
    worksheet.columns = [
      { header: 'Sno', key: 'sl_no' },
      { header: 'Date', key: 'transdate' },
      { header: 'Osmosys Code.', key: 'osmosyscode' },
      { header: 'Outlet Name', key: 'outletname' },
      { header: 'CSM Number', key: 'csmnumber' },
      { header: 'State', key: 'outletstatename' },
      { header: 'Region', key: 'outletregionname' },
      { header: 'Module Name', key: 'modulename' },
      { header: 'Avg. Time Spent (In Seconds)', key: 'timespent' },
    ]

    if (excelData.length > 0) {
      excelData.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {
          name: 'Arial',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'DAU Time Spent -' + this.datePipe.transform(new Date(), 'medium') + '.xlsx');
    })
  }


  EnagementTimeSpentExcel(excelData) {
    const header = FIRE_ENAGEMENT_TIME_SPENT;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Engagement Time Spent', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0000' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.font = {
        bold: true,
        name: 'Arial',
        size: 10,
        color: { argb: 'FFFFFF' }
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    }
    );
    worksheet.columns = [
      { header: 'Sno', key: 'sl_no' },
      { header: 'Osmosys Code.', key: 'osmosyscode' },
      { header: 'Outlet Name', key: 'outletname' },
      { header: 'CSM Number', key: 'csmnumber' },
      { header: 'State', key: 'outletstatename' },
      { header: 'Region', key: 'outletregionname' },
      { header: 'Engagement Name', key: 'engagementname' },
      { header: 'Time Spent (In Seconds)', key: 'timespent' },
    ]

    if (excelData.length > 0) {
      excelData.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {
          name: 'Arial',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Enagement Time Spent -' + this.datePipe.transform(new Date(), 'medium') + '.xlsx');
    })
  }


  MAUTimeSpentExcel(excelData) {
    const header = FIRE_MAU_TIME_SPENT;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('MAU Time Spent', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0000' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.font = {
        bold: true,
        name: 'Arial',
        size: 10,
        color: { argb: 'FFFFFF' }
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    }
    );
    worksheet.columns = [
      { header: 'Sno', key: 'sl_no' },
      { header: 'Month/Year', key: 'month_year' },
      { header: 'Osmosys Code.', key: 'osmosyscode' },
      { header: 'Outlet Name', key: 'outletname' },
      { header: 'CSM Number', key: 'csmnumber' },
      { header: 'State', key: 'outletstatename' },
      { header: 'Region', key: 'outletregionname' },
      { header: 'Module Name', key: 'modulename' },
      { header: 'Avg. Time Spent (In Seconds)', key: 'timespent' },
    ]

    if (excelData.length > 0) {
      excelData.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {
          name: 'Arial',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'MAU Time Spent -' + this.datePipe.transform(new Date(), 'medium') + '.xlsx');
    })
  }

  ModuleTimeSpentExcel(excelData) {
    const header = FIRE_MODULE_TIME_SPENT;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Module Time Spent', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0000' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.font = {
        bold: true,
        name: 'Arial',
        size: 10,
        color: { argb: 'FFFFFF' }
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    }
    );
    worksheet.columns = [
      { header: 'Sno', key: 'sl_no' },
      { header: 'Osmosys Code.', key: 'osmosyscode' },
      { header: 'Outlet Name', key: 'outletname' },
      { header: 'CSM Number', key: 'csmnumber' },
      { header: 'State', key: 'outletstatename' },
      { header: 'Region', key: 'outletregionname' },
      { header: 'Module Name', key: 'modulename' },
      { header: 'Time Spent (In Seconds)', key: 'timespent' },
    ]

    if (excelData.length > 0) {
      excelData.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {
          name: 'Arial',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Module Time Spent -' + this.datePipe.transform(new Date(), 'medium') + '.xlsx');
    })
  }


  SchemeTimeSpentExcel(excelData) {
    const header = FIRE_SCHEME_TIME_SPENT;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Scheme Time Spent', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0000' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.font = {
        bold: true,
        name: 'Arial',
        size: 10,
        color: { argb: 'FFFFFF' }
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    }
    );
    worksheet.columns = [
      { header: 'Sno', key: 'sl_no' },
      { header: 'Osmosys Code.', key: 'osmosyscode' },
      { header: 'Outlet Name', key: 'outletname' },
      { header: 'CSM Number', key: 'csmnumber' },
      { header: 'State', key: 'outletstatename' },
      { header: 'Region', key: 'outletregionname' },
      { header: 'Scheme Name', key: 'engagementname' },
      { header: 'Date Clicked', key: 'transaction_date' },
    ]

    if (excelData.length > 0) {
      excelData.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {
          name: 'Arial',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Scheme -' + this.datePipe.transform(new Date(), 'medium') + '.xlsx');
    })
  }


  GapAnalysisReponseExcel(excelData) {

    const finalHeaders = GAP_EXCEL;

    const secondHeaders = ['Osmosys Code', 'Mobile', 'Status', 'Month', 'year'];

    let workbook = new Workbook();


    let worksheet = workbook.addWorksheet("Users", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });



    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {

      if (number <= 8) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
          bgColor: { argb: "FF0000FF" },
        };
      }
      else {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "008000" },
          bgColor: { argb: "FF0000FF" },
        };
      }
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };



      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = [
      { header: 'Osmosys Code', key: 'rocode' },
      // { header: 'Classification', key: 'channel_desc' },
      { header: 'CSM Number', key: 'csm_mobile' },
      // { header: 'M1MobileNumber', key: 'm1_mobile' },
      { header: 'State', key: 'csm_state' },
      // { header: 'Region', key: 'csm_region' },
      // { header: 'Month', key: 'month' },
      // { header: 'Year', key: 'year' },
      { header: 'Bud4Trade Status', key: 'b4t_status' },
      { header: 'ABINBEV Database', key: 'abi_status' },
      { header: 'GAP Analysis', key: 'gap_remark' },
      { header: 'GAPS', key: 'status' },
    ];


    if (excelData.analysis_data_model.length > 0) {

      excelData.analysis_data_model.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {
          name: 'Calibri',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "GAP_Analysis_Computed" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });


  }
  GapAnalysisUserReport(excelData) {
    debugger
    const finalHeaders = GAP_EXCEL;

    const secondHeaders = ['Osmosys Code', 'Mobile', 'Status', 'Month', 'year'];

    let workbook = new Workbook();


    let worksheet = workbook.addWorksheet("Users", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });



    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {

      if (number <= 8) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
          bgColor: { argb: "FF0000FF" },
        };
      }
      else {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "008000" },
          bgColor: { argb: "FF0000FF" },
        };
      }
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };



      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = [
      { header: 'Osmosys Code', key: 'outlet_code' },
      // { header: 'Classification', key: 'channel_desc' },
      { header: 'CSM Number', key: 'csm_number' },
      // { header: 'M1MobileNumber', key: 'm1_mobile' },
      { header: 'State', key: 'state_code' },
      // { header: 'Region', key: 'csm_region' },
      // { header: 'Month', key: 'month' },
      // { header: 'Year', key: 'year' },
      { header: 'Bud4Trade Status', key: 'bud4trade_status' },
      { header: 'ABINBEV Database', key: 'abinbev_status' },
      { header: 'GAP Analysis', key: 'gap_analysis_remarks' },
      { header: 'GAPS', key: 'status' },
    ];

    if (excelData.gap_records.length > 0) {

      excelData.gap_records.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {
          name: 'Calibri',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "GAP_Analysis_Computed" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });


  }

  GapAnalysisNewUserExcel(excelData) {

    const secondHeaders = ['Osmosys Code', 'Mobile', 'Status', 'Month', 'Year'];

    let workbook = new Workbook();

    if (excelData.analysis_newusers_template_model.length > 0) {

      let newWorksheet = workbook.addWorksheet("New Users", {
        pageSetup: {
          horizontalCentered: true,
          verticalCentered: true,
          paperSize: 9,
          orientation: "portrait",
          margins: {
            left: 0.3149606,
            right: 0.3149606,
            top: 0.3543307,
            bottom: 0.3543307,
            header: 0.3149606,
            footer: 0.3149606,
          },
        },
      });

      let headerRow1 = newWorksheet.addRow(secondHeaders);

      headerRow1.eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "008000" },
          bgColor: { argb: "FF0000FF" },
        };
        cell.font = {
          bold: true,
          name: "Calibri",
          size: 10,
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };

        cell.protection = {
          locked: false,
        };
      });

      newWorksheet.columns = [
        { header: 'Osmosys Code', key: 'rocode' },
        { header: 'Mobile', key: 'csm_mobile' },
        { header: 'Status', key: 'gap_remark' }
      ];

      excelData.analysis_newusers_template_model.forEach(d => {
        let row = newWorksheet.addRow(d);
        row.font = {
          name: 'Calibri',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );

      newWorksheet.columns.forEach(function (column, i) {
        column.width = 26;
      });
    }

    const secondSheetHeaders = ['Osmosys Code', 'Outlet Name', 'CSM Name', 'CSM Mobile', 'Channel', 'M1 Name', 'M1 Mobile', 'Month', 'year'];

    if (excelData.analysis_data_model_new.length > 0) {
      let newWorksheet2 = workbook.addWorksheet("ABINBEV Users", {
        pageSetup: {
          horizontalCentered: true,
          verticalCentered: true,
          paperSize: 9,
          orientation: "portrait",
          margins: {
            left: 0.3149606,
            right: 0.3149606,
            top: 0.3543307,
            bottom: 0.3543307,
            header: 0.3149606,
            footer: 0.3149606,
          },
        },
      });

      let headerRow2 = newWorksheet2.addRow(secondSheetHeaders);

      headerRow2.eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "e1eb34" },
          bgColor: { argb: "FF0000FF" },
        };
        cell.font = {
          bold: true,
          name: "Calibri",
          size: 10,
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };

        cell.protection = {
          locked: false,
        };
      });

      newWorksheet2.columns = [
        { header: 'Osmosys Code', key: 'rocode' },
        { header: 'Outlet Name', key: 'roname' },
        { header: 'CSM Name', key: 'ownername' },
        { header: 'CSM Mobile', key: 'ownercontactno' },
        { header: 'Channel', key: 'channel_desc' },
        { header: 'M1 Name', key: 'salespersonname' },
        { header: 'M1 Mobile', key: 'mobileno' }
      ];

      excelData.analysis_data_model_new.forEach(d => {
        let row = newWorksheet2.addRow(d);
        row.font = {
          name: 'Calibri',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );

      newWorksheet2.columns.forEach(function (column, i) {
        column.width = 26;
      });
    }

    if (excelData.newuser_abdata.length > 0) {
      let newWorksheet2 = workbook.addWorksheet("ABINBEV Users", {
        pageSetup: {
          horizontalCentered: true,
          verticalCentered: true,
          paperSize: 9,
          orientation: "portrait",
          margins: {
            left: 0.3149606,
            right: 0.3149606,
            top: 0.3543307,
            bottom: 0.3543307,
            header: 0.3149606,
            footer: 0.3149606,
          },
        },
      });

      let headerRow2 = newWorksheet2.addRow(secondSheetHeaders);

      headerRow2.eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "e1eb34" },
          bgColor: { argb: "FF0000FF" },
        };
        cell.font = {
          bold: true,
          name: "Calibri",
          size: 10,
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };

        cell.protection = {
          locked: false,
        };
      });

      newWorksheet2.columns = [
        { header: 'Osmosys Code', key: 'rocode' },
        { header: 'Outlet Name', key: 'roname' },
        { header: 'CSM Name', key: 'ownername' },
        { header: 'CSM Mobile', key: 'ownercontactno' },
        { header: 'Channel', key: 'channel_desc' },
        { header: 'M1 Name', key: 'salespersonname' },
        { header: 'M1 Mobile', key: 'mobileno' }
      ];

      excelData.newuser_abdata.forEach(d => {
        let row = newWorksheet2.addRow(d);
        row.font = {
          name: 'Calibri',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );

      newWorksheet2.columns.forEach(function (column, i) {
        column.width = 26;
      });
    }

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "GAP_Analysis_New_Users_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });

  }

  GapAnalysisNewUserExcelTemplate() {

    const secondHeaders = ['Osmosys Code', 'Mobile'];

    let workbook = new Workbook();

    let newWorksheet = workbook.addWorksheet("New Users", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    let headerRow1 = newWorksheet.addRow(secondHeaders);

    headerRow1.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "008000" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    newWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "GAP_Analysis_New_Users_Template_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  exportGapAnalysisExcel(
    channel: ChannelModel[],
    region: RegionModel[],
    state: StateModel[],
  ) {

    const finalHeaders = GAP_EXCEL_TEMPLATE;
    const channels = channel.map((i) => i.channel_desc.trim()).join(",");
    const regions = region.map((i) => i.region_name.trim()).join(",");
    const states = state.map((i) => i.state_name.trim()).join(",");

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Users", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    /************STATE WORKSHEET***********************/

    let stateWorksheet = workbook.addWorksheet("States", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let stateHeaderRow = stateWorksheet.addRow(this.stateHeaders);

    // Cell Style : Fill and Border
    stateHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    stateWorksheet.columns = [{ header: "States", key: "state_name" }];

    state.forEach((d) => {
      let row = stateWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 10,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    stateWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /***************************************************/

    /************CHANNEL WORKSHEET***********************/

    // let channelWorksheet = workbook.addWorksheet("Channels", {
    //   pageSetup: {
    //     horizontalCentered: true,
    //     verticalCentered: true,
    //     paperSize: 9,
    //     orientation: "portrait",
    //     margins: {
    //       left: 0.3149606,
    //       right: 0.3149606,
    //       top: 0.3543307,
    //       bottom: 0.3543307,
    //       header: 0.3149606,
    //       footer: 0.3149606,
    //     },
    //   },
    // });

    // //Add Header Row
    // let channelHeaderRow = channelWorksheet.addRow(this.channelHeaders);

    // // Cell Style : Fill and Border
    // channelHeaderRow.eachCell((cell, number) => {
    //   cell.fill = {
    //     type: "pattern",
    //     pattern: "solid",
    //     fgColor: { argb: "9999FF" },
    //     bgColor: { argb: "FF0000FF" },
    //   };
    //   cell.font = {
    //     bold: true,
    //     name: "Calibri",
    //     size: 10,
    //   };
    //   cell.alignment = {
    //     vertical: "middle",
    //     horizontal: "center",
    //   };
    //   cell.border = {
    //     top: { style: "thin" },
    //     left: { style: "thin" },
    //     bottom: { style: "thin" },
    //     right: { style: "thin" },
    //   };
    // });

    // channelWorksheet.columns = [{ header: "Channels", key: "channel_desc" }];

    // channel.forEach((d) => {
    //   let row = channelWorksheet.addRow(d);
    //   (row.font = {
    //     name: "Calibri",
    //     size: 10,
    //   }),
    //     (row.alignment = {
    //       vertical: "middle",
    //       horizontal: "center",
    //     });
    // });

    // channelWorksheet.columns.forEach(function (column, i) {
    //   column.width = 26;
    // });

    /***************************************************/

    /************REGION WORKSHEET***********************/

    // let regionWorksheet = workbook.addWorksheet("Regions", {
    //   pageSetup: {
    //     horizontalCentered: true,
    //     verticalCentered: true,
    //     paperSize: 9,
    //     orientation: "portrait",
    //     margins: {
    //       left: 0.3149606,
    //       right: 0.3149606,
    //       top: 0.3543307,
    //       bottom: 0.3543307,
    //       header: 0.3149606,
    //       footer: 0.3149606,
    //     },
    //   },
    // });

    // //Add Header Row
    // let regionHeaderRow = regionWorksheet.addRow(this.regionHeaders);

    // // Cell Style : Fill and Border
    // regionHeaderRow.eachCell((cell, number) => {
    //   cell.fill = {
    //     type: "pattern",
    //     pattern: "solid",
    //     fgColor: { argb: "9999FF" },
    //     bgColor: { argb: "FF0000FF" },
    //   };
    //   cell.font = {
    //     bold: true,
    //     name: "Calibri",
    //     size: 10,
    //   };
    //   cell.alignment = {
    //     vertical: "middle",
    //     horizontal: "center",
    //   };
    //   cell.border = {
    //     top: { style: "thin" },
    //     left: { style: "thin" },
    //     bottom: { style: "thin" },
    //     right: { style: "thin" },
    //   };
    // });

    // regionWorksheet.columns = [{ header: "Regions", key: "region_name" }];

    // region.forEach((d) => {
    //   let row = regionWorksheet.addRow(d);
    //   (row.font = {
    //     name: "Calibri",
    //     size: 10,
    //   }),
    //     (row.alignment = {
    //       vertical: "middle",
    //       horizontal: "center",
    //     });
    // });

    // regionWorksheet.columns.forEach(function (column, i) {
    //   column.width = 26;
    // });

    /***************************************************/

    // Specify list of valid values (One, Two, Three, Four).
    // Excel will provide a dropdown with these values.

    let stateFormulae = [`${stateWorksheet.name}!A2:A${state.length + 1}`];

    // let regionFormulae = [`${regionWorksheet.name}!A2:A${region.length + 1}`];

    // let channelFormulae = [`${channelWorksheet.name}!A2:A${channel.length + 1}`];

    for (var i = 2; i < 50; i++) {
      // worksheet.getCell(`F${i}`).dataValidation = {
      //   type: "list",
      //   allowBlank: false,
      //   formulae: regionFormulae,
      //   promptTitle: "Region",
      //   prompt: "Select any region",
      //   showErrorMessage: true,
      // };

      worksheet.getCell(`C${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: stateFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };

      // worksheet.getCell(`B${i}`).dataValidation = {
      //   type: "list",
      //   allowBlank: false,
      //   formulae: channelFormulae,
      //   errorStyle: "error",
      //   errorTitle: "choose a channel",
      //   error: "please select",
      //   showErrorMessage: true,
      // };
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "GAP_Analysis_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }



  exportAchivementExcel(excelData) {
    const header = ACHIVEMENTS;
    const header1 = ["", "", "", "", "", "", "", "",
      "",
      "Achievement",
      'Points'];
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Outlet Wise Targets', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
    let headerRow1 = worksheet.addRow(header1);
    worksheet.getCell('J1').value = 'Target';
    worksheet.getCell('P1').value = 'Achievement';
    worksheet.getCell('U1').value = 'Points';
    worksheet.mergeCells('J1:P1');
    worksheet.mergeCells('Q1:W1');
    worksheet.mergeCells('X1:AD1');
    headerRow1.eachCell((cell, number) => {
      if (number <= 7) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '99CC00' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      else if (number >= 8 && number <= 9) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'B9D0E8' },
          bgColor: { argb: 'FF0000FF' },

        }
      }
      else if (number >= 10 && number <= 16) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '9999FF' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      else if (number >= 17 && number <= 23) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '90EE90' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      else if (number >= 24 && number <= 31) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F5F5F5' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      cell.font = {
        bold: true,
        name: 'Calibri',
        size: 9,
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    }
    );
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      if (number <= 7) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '99CC00' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      else if (number >= 8 && number <= 9) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'B9D0E8' },
          bgColor: { argb: 'FF0000FF' },

        }
      }
      else if (number >= 10 && number <= 16) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '9999FF' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      else if (number >= 17 && number <= 23) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '90EE90' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      else if (number >= 24 && number <= 31) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F5F5F5' },
          bgColor: { argb: 'FF0000FF' }
        }
      }
      cell.font = {
        bold: true,
        name: 'Calibri',
        size: 9,
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    }
    );

    worksheet.columns = [
      { header: '', key: 'sl_no' },
      { header: '', key: 'ro_code' },
      { header: '', key: 'ro_name' },
      { header: '', key: 'tse_name' },
      { header: '', key: 'asm_name' },
      { header: '', key: 'state_name' },
      { header: '', key: 'channel_desc' },
      { header: '', key: 'csm_name' },
      { header: '', key: 'mobile_no' },
      { header: 'Haywards/Knockout', key: 'core_target_qty' },
      { header: 'Beck\'s Ice', key: 'becks_target_qty' },
      { header: 'Budweiser', key: 'premium_target_qty' },
      { header: 'Magnum', key: 'magnum_target_qty' },
      { header: 'Corona/Hoegaarden', key: 'ipb_target_qty' },
      { header: 'Seven Rivers', key: 'innovation_target_qty' },
      { header: 'Target', key: 'total_target_qty' },
      { header: 'Haywards/Knockout', key: 'core_achieved_qty' },
      { header: 'Beck\'s Ice', key: 'becks_achieved_qty' },
      { header: 'Budweiser', key: 'premium_achieved_qty' },
      { header: 'Magnum', key: 'magnum_achieved_qty' },
      { header: 'Corona/Hoegaarden', key: 'ipb_achieved_qty' },
      { header: 'Seven Rivers', key: 'innovation_achieved_qty' },
      { header: 'Achievement', key: 'total_achieved_qty' },
      { header: 'Haywards/Knockout', key: 'core_points' },
      { header: 'Beck\'s Ice', key: 'becks_points' },
      { header: 'Budweiser', key: 'premium_points' },
      { header: 'Magnum', key: 'magnum_points' },
      { header: 'Corona/Hoegaarden', key: 'ipb_points' },
      { header: 'Seven Rivers', key: 'innovation_points' },
      { header: 'Points', key: 'total_points' }

    ]

    excelData.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = {
        name: 'Arial',
        size: 10,
      },
        row.alignment = {
          vertical: 'middle', horizontal: 'center'
        };
    }
    );

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'T&A Master Monthly Report_' + this.datePipe.transform(new Date(), "medium") + '.xlsx');
    })
  }


  exportWhiteListReportExcel(excelData: any[]) {
    const finalHeaders = WHITELISTING_REPORT_EXCEL;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Whitelisting Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    debugger
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",


        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    worksheet.columns = [
      { header: 'Outlet Code', key: 'dummy_rocode' },
      { header: 'Outlet Name', key: 'ro_name' },
      { header: 'King Service', key: 'kingservice' },
      { header: 'Content & Learning', key: 'contentlearn' },
      { header: 'Weekly Challenges', key: 'tactical' },
      { header: 'Branding', key: 'branding' },
      { header: 'Cooler', key: 'cooler' },
      { header: 'Region', key: 'region_name' },
      { header: 'State Name', key: 'state_name' }
    ]

    excelData.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = {
        name: 'Calibri',
        size: 8,
      },
        row.alignment = {
          vertical: 'middle', horizontal: 'center'
        };
    }
    );

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Whitelisting_Excel_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  settingsHeaders: ColumnSetting[];
  columnMaps: ColumnSetting[];

  exportExceptionReportExcel(records: any[], excelName) {
    this.columnMaps = Object.keys(records[0])
      .map(key => {
        return {
          primaryKey: key,
          header: key.slice(0, 1).toUpperCase() +
            key.replace(/_/g, ' ').slice(1)
        }
      });

    const finalHeaders = this.columnMaps.map(i => i.header);
    //const finalHeaders = WHITELISTING_REPORT_EXCEL;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(excelName + " Uploaded Sheet", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let columns = [];

    this.columnMaps.forEach(element => {
      let ColumnModelData: ColumnModel = new ColumnModel;
      ColumnModelData.header = element.header;
      ColumnModelData.key = element.primaryKey;
      columns.push(ColumnModelData);
    });

    worksheet.columns = columns;
    records.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = {
        name: 'Calibri',
        size: 8,
      },
        row.alignment = {
          vertical: 'middle', horizontal: 'center'
        };
    }
    );

    //Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        excelName + "_Status_Report_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  GapAnalysisExcelDoenload(excelData) {
    const finalHeaders = GAP_EXCEL;
    console.log('excelData')
    console.log(excelData)
    console.log(finalHeaders)

    // const secondHeaders = ['Osmosys Code', 'Mobile', 'Status','Month','year'];

    let workbook = new Workbook();


    let worksheet = workbook.addWorksheet("Gao Analysis Report", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });



    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {

      if (number <= 8) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
          bgColor: { argb: "FF0000FF" },
        };
      }
      else {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "008000" },
          bgColor: { argb: "FF0000FF" },
        };
      }
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };



      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = [
      { header: 'Osmosys Code', key: 'outlet_code' },
      { header: 'Classification', key: 'channel_desc' },
      { header: 'CSM Number', key: 'csm_number' },
      { header: 'M1MobileNumber', key: 'm1_mobile_number' },
      { header: 'State', key: 'state_name' },
      { header: 'Region', key: 'region_name' },
      { header: 'Month', key: 'gap_analysis_month' },
      { header: 'Year', key: 'gap_analysis_year' },
      { header: 'Bud4Trade Status', key: 'bud4trade_status' },
      { header: 'ABINBEV Database', key: 'abinbev_status' },
      { header: 'GAP Analysis', key: 'gap_analysis' },
      { header: 'GAPS', key: 'gaps' },
    ];


    if (excelData.length > 0) {

      excelData.forEach(d => {
        let row = worksheet.addRow(d);
        row.font = {
          name: 'Calibri',
          size: 10,
        },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
      }
      );
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "GAP_Analysis_Computed" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });


  }

}

export class ColumnModel {
  header: string;
  key: string;
}

export class ColumnModelNew {
  headernew: string;
  keynew: string;
}

export class OutletModel {
  ro_code: string;
  targetData: any;
}

export class OutletLoyaltyModel {
  brand_category_name_target_qty: string;
  target_qty: string;
}
