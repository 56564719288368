import { BrowserModule } from "@angular/platform-browser";
import { Injector, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SigninComponent } from "./common/signin/signin.component";
import {
  HttpClientModule,
  HttpXhrBackend,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { HttpConfigInterceptor } from "./services/interceptor/http-loading-interceptor";
import { XlsxViewComponent } from "./shared/xlsx-view/xlsx-view.component";
import { SharedModule } from "./shared/shared.module";
import { Router } from "@angular/router";
import { CacheInterceptor } from "./shared/Http/cache.interceptor";
import { ToastComponent } from "./shared/toast/toast.component";
import { ToastService } from "./services/toast/toast.service";
import { DatePipe } from "@angular/common";
import { ExportExcelService } from "./shared/xlsx-view/export-excel.service";
import { ColorPaletteComponent } from "./shared/color-palette/color-palette.component";
import { UploadExcelDetailsComponent } from "./shared/upload-excel-details/upload-excel-details.component";
import { UploadZipService } from "./services/upload-zip/upload-zip.service";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { GeneralTableComponent } from "../app/shared/general-table/general-table.component";



@NgModule({
  declarations: [AppComponent, ToastComponent, UploadExcelDetailsComponent, GeneralTableComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule.forRoot(),
    CarouselModule.forRoot(),
    HttpClientModule,
  ],
  providers: [
    
    ToastService,
    DatePipe,
    ExportExcelService,
    UploadZipService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
  ],
  entryComponents: [XlsxViewComponent, ColorPaletteComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
